import EventEmitter from "event-lite";

import { fetchRef as gqlFetchRef } from "@/__main__/get-data.mjs";
import mainRefs from "@/__main__/refs.mjs";
import type { ReplayMode } from "@/feature-event-replay/lol-actions.mjs";
import {
  setupState,
  teardownState,
} from "@/feature-event-replay/lol-actions.mjs";
import {
  fetchInterceptor,
  gqlFetchInterceptor,
  handleLcuConnection,
  handleRecording,
} from "@/feature-event-replay/lol-api.mjs";
import RecordingToolbar from "@/feature-event-replay/RecordingToolbar.jsx";
import { fetchRef as inGameFetchRef } from "@/game-lol/utils/in-game-external-api.mjs";
import {
  EVENT_CONNECTION_INIT,
  EVENT_LCU,
  events as lolEvents,
} from "@/game-lol/utils/lol-client.mjs";
import { reRender } from "@/shared/EventedRender.jsx";
import globals from "@/util/global-whitelist.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";
import symbolName from "@/util/symbol-name.mjs";

export const replayEvents = new EventEmitter();
export const EVENT_LCU_REQUEST = symbolName("lcu-request");

interface Recording {
  name: string;
  game: string;
  lastAccessedTime: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events: any[];
  [key: symbol]: unknown;
}

interface EventReplayState {
  mode: ReplayMode;
  currentStep: number;
  totalSteps: number;
  requestMap: object;
  requestHistory: object;
  recordings: Record<string, Recording>;
  currentRecording: Array<object>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedRecording: any;
  captureGql: boolean;
  recordingIds: string[];

  latestSteps?: {
    [key: string]: number;
  };

  latestRecordingId?: {
    [key: string]: boolean;
  };
}

declare module "@/__main__/app-state.mjs" {
  interface FeatureState {
    eventReplay: EventReplayState;
  }
}

const wrapFetch = (method, endpoint, body) =>
  fetchInterceptor(endpoint, { method, body });

const originals = mapOriginalRefs({
  mainRefComponents: mainRefs.components,
});

export function setup() {
  setupState();

  originals.append({
    mainRefComponents: {
      floatingElementsInner: [RecordingToolbar],
    },
  });

  inGameFetchRef.fetch = fetchInterceptor;
  gqlFetchRef.fetch = gqlFetchInterceptor;
  reRender();

  lolEvents.on(EVENT_CONNECTION_INIT, handleLcuConnection);
  lolEvents.on(EVENT_LCU, handleRecording);
  replayEvents.on(EVENT_LCU_REQUEST, wrapFetch);
}

export function teardown() {
  originals.restore();

  inGameFetchRef.fetch = globals.fetch;
  gqlFetchRef.fetch = globals.fetch;
  reRender();

  lolEvents.off(EVENT_CONNECTION_INIT, handleLcuConnection);
  lolEvents.off(EVENT_LCU, handleRecording);
  replayEvents.off(EVENT_LCU_REQUEST, wrapFetch);
  teardownState();
}

// Re-exporting for tests.
export * as lolActions from "@/feature-event-replay/lol-actions.mjs";
