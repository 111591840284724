import { styled } from "goober";

export const Toolbar = styled("div")`
  display: flex;
  justify-content: end;
  position: fixed;
  gap: var(--sp-2);
  padding: var(--sp-2);
  bottom: var(--sp-2);
  left: var(--sp-2);
  border-radius: var(--br-lg);
  z-index: 4;
  opacity: 0.4;

  &.open {
    background: var(--shade10);
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }
`;

export const Form = styled("form")`
  display: flex;
  position: relative;
  gap: var(--sp-2);
  padding: 0;
`;

export const sharedInput = `
  border-radius: var(--br);
  background-color: var(--shade7);
  box-shadow: 0 var(--sp-1) var(--sp-2) var(--shade10);
  font-family: inherit;
  font-size: 0.75rem;
  color: var(--shade0);
  padding: var(--sp-3);
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
    background-color: var(--shade6);
  }

  svg {
    width: var(--sp-5);
    height: auto;
  }
`;

export const Select = styled("select")`
  ${sharedInput}
`;

export const Input = styled("input")`
  ${sharedInput}
`;

export const Textarea = styled("textarea")`
  ${sharedInput}
  height: 20rem;
  width: 30rem;
  padding: var(--sp-2);
  cursor: text;
`;

export const TextContainer = styled("div")`
  & > pre {
    background: var(--shade10);
    margin: 0;
    padding: 1rem;
    height: 60ch;
    width: 70ch;
    overflow: hidden;
    overflow-y: scroll;

    ::selection {
      /* WebKit/Blink Browsers */
      background: var(--shade7);
      color: var(--shade1);
    }
  }
`;

export const Steps = styled("span")`
  display: flex;
  height: var(--sp-8);
  align-items: center;
  padding: 0 var(--sp-2);

  i {
    width: 1px;
    height: 20px;
    transform: rotate(30deg);
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 8px 0 10px;
  }
`;

export const Modal = styled("div")`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;
